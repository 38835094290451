import { H1, YStack } from '@holo/ui';
import type { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: await serverSideTranslations(locale ?? 'en'),
  };
};

const ErrorComponent = () => (
  <YStack height="100vh" alignItems="center" justifyContent="center">
    <H1>404</H1>
  </YStack>
);

export default ErrorComponent;
